import {getMapOptions} from "../config/map";

export class SearchBox {

    constructor(mapComponent) {

        this._mapComponent = mapComponent;
        this._gMap = this._mapComponent.map;

        // form
        this._inputEl = this._mapComponent.element.getElementsByTagName('input')[0];
        this._searchButton = this._mapComponent.element.getElementsByTagName('button')[0];
        this._gInput = null;

        this._initialize();
    }


    // update search bias based on current map bounds.
    setBias(bounds) {
        this._gInput.setBounds(bounds);
    }

    _initialize() {

        this._gInput = new google.maps.places.SearchBox(this._inputEl);
        this._addEventListeners();
    }

    _addEventListeners() {

        this._searchButton.addEventListener('click', (e) => {

            if (this._inputEl.value !== '' && !this._searchButton.classList.contains('has-value')) {

                google.maps.event.trigger(this._inputEl, 'focus', {});
                google.maps.event.trigger(this._inputEl, 'keydown', { keyCode: 13 });
                google.maps.event.trigger(e.target, 'focus', {});

            } else if (this._inputEl.value !== '' && this._searchButton.classList.contains('has-value')) {
                // clear input
                this._inputEl.value = null;
                this._inputEl.focus();
                this._searchButton.classList.remove('has-value');
                this._gMap.setOptions(getMapOptions());
            }

        });

        // on search, update map bounds.
        this._gInput.addListener("places_changed", () => {

            this._searchButton.classList.add('has-value');

            const _place = this._gInput.getPlaces()[0];

            if (_place['geometry']) {

                // adjust map to fit location bounds
                const _bounds = new google.maps.LatLngBounds();

                if (_place.geometry.viewport) {
                    _bounds.union(_place.geometry.viewport);
                } else {
                    _bounds.extend(_place.geometry.location);
                }

                this._gMap.fitBounds(_bounds);

                this._mapComponent.scrollIntoView();
            }
        });
    }
}
