export function loadScript(id, src, callback) {

  const _tag = document.createElement('script');

  _tag.src = src;
  _tag.async = _tag.defer = true;
  _tag.onload = callback;
  _tag.id = id;

  const _firstScriptTag = document.getElementsByTagName('script')[0];
  _firstScriptTag.parentNode.insertBefore(_tag, _firstScriptTag);
}
