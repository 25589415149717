export default function faq() {
    const questions = document.querySelectorAll('.faq__question-button');

    if (!questions) return;

    function toggleQuestion(e) {
        let item;

        if (e) item = e.srcElement || e.target;

        item.parentElement.classList.toggle('faq__question--active');
    }

    for (let i = 0; i < questions.length; i++) {
        questions[i].addEventListener('click', toggleQuestion, false)
    }
}
