import Swiper from 'swiper';

export default function reviewSwiper() {

    var mySwiper = new Swiper('.swiper-container', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
    })
}
