export const addressStrToHTML = function(address) {

    const _parts = address.split(', ');

    let _returnStr = '';

    for (let i = 0; i < _parts.length; i++) {
        if (_parts[i].toLowerCase() !== 'nederland') {
            _returnStr += `<span>${_parts[i]}</span>`;
        }
    }

    return _returnStr;
}
