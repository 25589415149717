export default function playHeaderVideo() {
    const headerVideo = document.querySelector('.header__video');
    if (headerVideo) {
        const headerVideoElement = headerVideo.querySelector('video');
        const headerContent = document.querySelector('.header__content');

        document.querySelector('.header__button').addEventListener('click', () => {
            headerVideoElement.play();
            headerContent.classList.add('header__content--hidden');
            headerVideo.classList.add('header__overlay--hidden');
        });

        headerVideoElement.addEventListener('click', () => {
            headerVideoElement.pause();
            headerContent.classList.remove('header__content--hidden');
            headerVideo.classList.remove('header__overlay--hidden');
        });
    }

    const _slides = document.getElementsByClassName('header__image');

    let _curSlideIndex = _slides.length - 1;

    const _nextSlide = () => {

        if (_slides[_curSlideIndex] !== undefined) {
            _slides[_curSlideIndex].classList.remove('show');
        }

        if (_curSlideIndex < _slides.length - 1) {
            _curSlideIndex += 1;
        } else {
            _curSlideIndex = 0;
        }

        if (_slides[_curSlideIndex] !== undefined) {
            _slides[_curSlideIndex].classList.add('show');
        }
    };

    _nextSlide();

    setInterval(() => {
        _nextSlide();
    }, 10000);
}
