import {sortById} from "../utils/sort";

export class TemplateData {

    constructor(rootEl, name) {

        this._id = rootEl.id;
        this._dataEl = rootEl.getElementsByClassName(name + '__data')[0];
        this._data = null;

        this._initialize();
    }

    _initialize() {
        this._getData();
        this._clearData();
    }

    _getData() {

        if (this._dataEl) {

           const _data = document['data_' + this._id];

           if (_data.labels) {
               // sort labels by id (shouldn't be needed)
               _data.labels = _data.labels.sort(sortById);
           }

           this._data = _data;

       } else {
           console.warn('no map data found!');
       }
    }


    _clearData() {
        if (this._dataEl) {
            this._dataEl.parentNode.removeChild(this._dataEl);
        }
    }

    get data() {
        return this._data;
    }
}
