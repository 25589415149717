import './main.scss';
import 'babel-polyfill';

import navigationToggle from './blocks/navigation/navigation';
import filterCows from './blocks/cow/cow';
import reviewSwiper from './blocks/review/review';
import faq from './blocks/faq/faq';
import form from './blocks/form/form';
import playHeaderVideo from './blocks/header/header';
import cookie from './blocks/cookie/cookie';
import feed from './blocks/feed/feed';
import scrollanimation from './blocks/scrollanimation/scrollanimation';
import storyContainer from './blocks/story/story-container';
import productCarousel from './blocks/product-carousel/product-carousel';
import productionProcess from './blocks/production-process/production-process';
import {mapComponent} from './pages/map-page/map-component';
import {contactForm} from './pages/map-page/components/contact-form';
import {initStoryGrid} from './blocks/story-grid/story-grid'

navigationToggle();
filterCows();
reviewSwiper();
faq();
form();
playHeaderVideo();
cookie();
feed();
scrollanimation();
storyContainer();
productCarousel();
mapComponent();
contactForm();
productionProcess();
initStoryGrid();
