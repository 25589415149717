export default function filterCows() {
    let DEFAULT_COW;
    const SHOW_COW_CLASS = "cow__show";

    const cowPage = document.querySelector('.cow-page');

    if (!document.querySelector('.cow-page')) {
        DEFAULT_COW = "cow__Aukje";
    }
    else {
        DEFAULT_COW = cowPage.dataset.cowName;
    }

    // show cow aukje as default
    filterSelection(DEFAULT_COW);

    const cowFilters = document.querySelectorAll('.cow__filter-button');
    for (let i = 0; i < cowFilters.length; i++) {
        cowFilters[i].addEventListener("click", function () {
            let cowName = "cow__" + cowFilters[i].innerHTML;
            filterSelection(cowName);
        });
    }

    function filterSelection(cowName) {
        const cowFilters = document.querySelectorAll('.cow__filter-button');
        if (document.querySelector('.cow__filter-button--active')) {
            document.querySelector('.cow__filter-button--active').classList.remove('cow__filter-button--active')
        }
        for (let i = 0; i < cowFilters.length; i++) {
            if (cowFilters[i].classList.contains(cowName)) {
                cowFilters[i].classList.add('cow__filter-button--active');
            }
        }
        let cows = document.getElementsByClassName("cow");
        for (let i = 0; i < cows.length; i++) {
            hideCow(cows[i], SHOW_COW_CLASS);
            if (cows[i].classList.contains(cowName)) {
                showCow(cows[i], SHOW_COW_CLASS);
            }
        }
    }

    function showCow(element, name) {
        const arr1 = element.className.split(" ");
        const arr2 = name.split(" ");
        for (let i = 0; i < arr2.length; i++) {
            if (arr1.indexOf(arr2[i]) === -1) {
                element.className += " " + arr2[i];
            }
        }
    }

    function hideCow(element, name) {
        const arr1 = element.className.split(" ");
        const arr2 = name.split(" ");
        for (let i = 0; i < arr2.length; i++) {
            while (arr1.indexOf(arr2[i]) > -1) {
                arr1.splice(arr1.indexOf(arr2[i]), 1);
            }
        }
        element.className = arr1.join(" ");
    }
}
