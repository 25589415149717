import {loadScript} from "../utils/load-script";
import {elevation} from "@googlemaps/google-maps-services-js/dist/elevation";

class ContactForm {

    constructor(el) {

        this.domElement = el;

        this._form = this.domElement.getElementsByTagName('form')[0];
        this._recaptchaKey = this._form.getAttribute('data-key');

        this._formDefault = this.domElement.getElementsByClassName('form__default')[0];
        this._formSuccess = this.domElement.getElementsByClassName('form__success')[0];
        this._formWarning = this.domElement.getElementsByClassName('form__warning')[0];

        this._initialize();

        window.onloadCallback = this._onloadCallback
    }

    _initialize() {

        if (this._recaptchaKey) {
            this._loadRecaptcha();

        }
        this._addEventListeners();
    }

    // loads recaptcha (only once, when needed)
    _loadRecaptcha() {
        loadScript('recaptcha', 'https://www.google.com/recaptcha/api.js?render=' + this._recaptchaKey )
        // const _recaptcha = document.getElementById('recaptcha');
        // if (!_recaptcha) {
        //     loadScript('recaptcha', 'https://www.google.com/recaptcha/api.js?render=' + this._recaptchaKey + '&onload=onloadCallback')
        // }
    }

    _addEventListeners() {

        this._form.addEventListener('submit', (e) => {
            e.preventDefault();
            e.stopPropagation();

            if (this._recaptchaKey) {
                // run recaptcha first
                grecaptcha.ready(() => {
                    grecaptcha.execute(this._recaptchaKey, {action: 'submit'}).then((token) => {
                        document.getElementById('form__recaptcha__input').value = token;
                        this._ajaxSubmitForm(this._form.method, this._form.action, new FormData(this._form));
                    });
                })
            } else {
                console.warn('No recaptcha key provided');
            }
        });
    }

    // on form submit
    _ajaxSubmitForm(method, action, data) {

        const _xHttp = new XMLHttpRequest();
        _xHttp.onreadystatechange = (event) => {
            if (_xHttp.readyState === XMLHttpRequest.DONE) {
                if (_xHttp.status === 200 && (JSON.parse(_xHttp.response).success === true) && JSON.parse(_xHttp.response).recaptcha === true) {
                    this._showSuccess();
                } else if (JSON.parse(_xHttp.response).recaptcha === false) {
                    console.warn('Error: Recaptcha verification failed. Please refresh the page and try again');
                    this._formWarning.classList.add('visible');
                } else {
                    console.warn('error submitting form');
                    this._formWarning.classList.add('visible');
                }
            }
        };

        _xHttp.open(method, action, true);
        _xHttp.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        _xHttp.send(data);
    }

    _showSuccess() {
        this._formDefault.classList.remove('visible');
        this._formSuccess.classList.add('visible');
    }

    _onloadCallback() {
        const sitekey = document.querySelector('#contact-form').dataset.key

        grecaptcha.render('form__recaptcha__input', {
            sitekey: sitekey

        });
    }

}

export const contactForm = function () {
    const _contactForm = document.getElementsByClassName('contact-form')[0];
    if (_contactForm) {
        new ContactForm(_contactForm);
    }
};
