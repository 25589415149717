export default function scrollanimation() {
    ScrollReveal().reveal('.header__content', {
        delay: 600,
        duration: 1000,
        interval: 200,
        distance: '20px',
        scale: 1.2,
        easing: 'cubic-bezier(0.5, 0, 0, 0.5)',
        reset: false,
        // , mobile: false
        //,opacity: 0.5
    });

    ScrollReveal().reveal('.usp__item, .feed__wrapper, .farmer-block, .instagram-item, .image-collection__item, .image-text .wrapper', {
        delay: 250,
        duration: 500,
        interval: 200,
        //,opacity: 0.5,
        distance: '20px',
        //, scale: 0.8
        easing: 'cubic-bezier(0.2, 0, 0, 0.2)',
        reset: false,
        // , mobile: false
        viewOffset: {
            top: 0,
            right: 0,
            bottom: 100,
            left: 0,
        }
    });
    ScrollReveal().reveal('.product-overview__bottle, .image__image', {
        delay: 150,
        duration: 400,
        interval: 200,
        opacity: 0,
        easing: 'cubic-bezier(0.2, 0, 0, 0.2)',
        reset: false,
    });

    /*    ScrollReveal().reveal('.cow__wrapper', {
            delay: 300
            , duration: 1200
            , interval: 600
            //,opacity: 0.5
            //, distance: '40px'
            //, scale: 0.8
            ,easing: 'cubic-bezier(0.5, 0, 0, 0.5)'
            , mobile: false
        });*/


}
