import {loadScript} from "../utils/load-script";

export class AssetLoader {

    constructor(key, onComplete) {

        this._sourcesLoaded = 0;
        this._callback = onComplete;

        if (!key) {
            console.warn('No Google maps API key set.');
        }

        this._srcs = [
            {
                id: 'cluster-markers',
                url: 'https://cdnjs.cloudflare.com/ajax/libs/markerclustererplus/2.1.4/markerclusterer.min.js'
            },
            {
                id: 'google-maps',
                url: `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&v=weekly&language=nl`
            }
        ]
    }

    load() {

        for (let i = 0; i < this._srcs.length; i++) {
            const _script = this._srcs[i];
            loadScript(_script.id, _script.url, this._onScriptLoaded.bind(this));
        }
    }

    _onScriptLoaded() {

        this._sourcesLoaded ++;

        if (this._sourcesLoaded === this._srcs.length && this._callback) {
            this._callback();
        }
    }
}
