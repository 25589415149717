import {AssetLoader} from "./components/asset-loader";
import {docReady} from "./utils/doc-ready";
import {InfoWindow} from "./components/info-window";
import {SearchBox} from "./components/search-box";
import {getClusterOptions, getMapOptions, getMarkerStyle} from "./config/map";
import {TemplateData} from "./components/template-data";

class MapComponent {

    constructor(element) {

        this._name = 'map-component';

        this._element = element;
        this._mapEl = this._element.getElementsByClassName(this._name + '__map')[0];
        this._filters = this._element.querySelector('.map-component__filters')

        this._data = null;
        this._activeData = null;
        this._assetLoader = null;
        this._gMap = null;
        this._markers = []
        this._infoWindow = null;
        this._markerClusters = null;

        docReady(this._initialize.bind(this));
    }

    // scroll map into view
    scrollIntoView() {

        const _mdBreakpoint = window.matchMedia("(max-width: 960px)");

        if (_mdBreakpoint.matches) {
            const _y = this._element.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({
                top: _y,
                behavior: 'smooth'
            });
        }
    }

    _initialize() {
        this._data = new TemplateData(this._element, this.name).data;
        this._assetLoader = new AssetLoader(this._data.key, this._onAssetsLoaded.bind(this)).load();
    }

    _onAssetsLoaded() {
        this._setupMap();

        this._infoWindow = new InfoWindow(this, this._data.labels);
        this._searchBox = new SearchBox(this);

        this._addEventListeners();
    }

    _addEventListeners() {

        // bias _gInput results towards current map's viewport.
        this._gMap.addListener("bounds_changed", () => {
            this._searchBox.setBias(this._gMap.getBounds());
        });

        google.maps.event.addListener(this._gMap, 'zoom_changed', () => {
            this._infoWindow.close();
        });

        this._filters.addEventListener('change', () => {
            this._handleFilterChange()
        })
    }

    // SETUP

    _handleFilterChange() {
        const productFilters = [...this._filters.querySelectorAll('.custom-checkbox__input[name="product"]:checked')].map(filter => filter.value)
        let filteredProducts = this._data.locations

        if (productFilters.length) {
            filteredProducts = this._data.locations.filter(
                location => productFilters.filter(
                    filter => location.products.includes(filter)
                ).length === productFilters.length
            )
        }

        this._infoWindow.close()
        this._deleteMarkers()
        this._addLocations(filteredProducts, 'location', true);
        this._addLocations(this._data.farms, 'farm', false);
    }

    _setupMap() {

        this._gMap = new google.maps.Map(this._mapEl, getMapOptions());

        if (!this._data) {
            console.warn('No map data to display.');
            return;
        }

        if (this._data.locations) {
            this._addLocations(this._data.locations, 'location', true);
        }

        if (this._data.farms) {
            this._addLocations(this._data.farms, 'farm', false);
        }
    }

    _deleteMarkers() {
        for (let i = 0; i < this._markers.length; i++) {
            this._markers[i].setMap(null);
        }

        this._markers = []
        this._markerClusters.clearMarkers()
        this._markerClusters = null
    }

    _addLocations(locations, type, cluster) {
        const _markerStyle = getMarkerStyle();

        Array.from(locations).forEach((location) => {
            if (location.point) {
                const _style = _markerStyle[type];

                const _marker = new google.maps.Marker({
                    position: location.point,
                    icon: {
                        url: _style.icon,
                    },
                    map: (cluster) ? null : this._gMap,
                    optimized: false //IE 11
                });

                google.maps.event.addListener(_marker, 'click', () => {
                    this._infoWindow.toggle(_marker, type, location);
                    this._offsetCenter(location.point, 0, -150);
                });

                this._markers.push(_marker);
            }

        });

        if (cluster) {
            this._clusterMarkers(this._markers);
        }
    }

    _offsetCenter(latlng, offsetx, offsety) {

        var _latlng = new google.maps.LatLng(latlng.lat, latlng.lng);

        var scale = Math.pow(2, this._gMap.getZoom());

        var worldCoordinateCenter = this._gMap.getProjection().fromLatLngToPoint(_latlng);
        var pixelOffset = new google.maps.Point((offsetx / scale) || 0, (offsety / scale) || 0);

        var worldCoordinateNewCenter = new google.maps.Point(
            worldCoordinateCenter.x - pixelOffset.x,
            worldCoordinateCenter.y + pixelOffset.y
        );

        var newCenter = this._gMap.getProjection().fromPointToLatLng(worldCoordinateNewCenter);

        this._gMap.panTo(newCenter);
    }

    _clusterMarkers(markers) {
        this._markerClusters = new MarkerClusterer(this._gMap, markers, getClusterOptions());
    }

    get map() {
        return this._gMap;
    }

    get element() {
        return this._element;
    }

    get name() {
        return this._name;
    }
}

export const mapComponent = function () {
    const _mapComponent = document.getElementsByClassName('map-component')[0];
    if (_mapComponent) {
        new MapComponent(_mapComponent);
    }
};
