export default function form()
{
    if (document.querySelector('.form')) {
        document.querySelector('.form__checkbox').addEventListener('change', () => {
            if (document.querySelector('input[type="hidden"]').checked) {
                document.querySelector('input[type="hidden"]').checked = false;
                // document.querySelector('input[type="hidden"]').click();
            }
            else {
                document.querySelector('input[type="hidden"]').checked = true;
                // document.querySelector('input[type="hidden"]').click();
            }
            document.querySelector('input[type="hidden"]').click();
            console.log(document.querySelector('input[type="hidden"]').checked);
        })
    }
}
