import Swiper from 'swiper';

export default function storyContainer() {
    const blockName = 'story-container';

    function setupStoryContainer(block) {
        const el = block.getElementsByClassName(`${blockName}__list`)[0];
        const slideCount = parseInt(el.dataset.count, 10) || -1;
        const showVertically = el.dataset.vertical === 'True' || false;
        let container;

        function getSlidesPerColumn(slidesPerRow) {
            if (showVertically) {
                return Math.ceil(slideCount / slidesPerRow) || -1;
            }
            return -1;
        }

        function getTouchRatio(showOnMobile) {
            if (showVertically) {
                return 0;
            }
            return 1;
        }

        function _initStoryContainer() {
            container = new Swiper(el, {
                freeMode: true,
                spaceBetween: 15,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 3
                },
                observer: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },

                touchRatio: getTouchRatio(false),

                breakpoints: {
                    0: {
                        slidesPerView: 1,
                        slidesPerColumn: getSlidesPerColumn(1),
                    },
                    768: {
                        slidesPerView: 2,
                        slidesPerColumn: getSlidesPerColumn(2),
                    },
                    1024: {
                        slidesPerView: 3,
                        slidesPerColumn: getSlidesPerColumn(3),
                    },
                },
            });
            container.init();
        }

        _initStoryContainer();
    }

    Array.from(document.getElementsByClassName(blockName)).forEach((container) => {
        setupStoryContainer(container);
    });
}
