import 'whatwg-fetch'
import Cookies from 'js-cookie/src/js.cookie';

export default function feed() {
    let loading = false;
    const feedSelector = '.feed';

    const feed =  document.querySelector(feedSelector)

    function getFeedEl() {
        return feed.parent.querySelector(feedSelector);
    }

    function getLastPk() {
        return getFeedEl().dataset.lastPk
    }

    function getCowPk() {
        return getFeedEl().dataset.cowPk
    }

    function showLoader() {
        loading = true;
        getFeedEl().querySelector('.feed__loader').classList.add('feed__loader--active')
    }

    function hideLoader() {
        loading = false;
        getFeedEl().querySelector('.feed__loader').classList.remove('feed__loader--active')
    }

    function init() {
        if (feed) {
            setInterval(update, 1000 * 10)
        }
    }

    function update() {
        if (loading) {
            return
        }
        showLoader();
        setTimeout(doFetch, 1000 * 5)
    }

    function doFetch() {
        let data = new FormData();
        data.append('lastPk', getLastPk());
        data.append('cowPk', getCowPk());

        fetch('/feed', {
            credentials: 'same-origin',
            method: 'POST',
            body: data,
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken')
            },
        }).then((response) => {
            hideLoader();
            return response.text();
        }).then((data) => {
            if (data) {
                feed.parent.innerHTML = data;
                Array.from(getFeedEl().querySelectorAll('.feed__wrapper')).map((el) => {
                    el.style.visibility = 'visible'
                });
            }
        })
    }

    init();

}
