export class Underlay {

    constructor(gMap) {

        this._gMap = gMap;
        this._element = null;

        this._create()
    }

    _create () {

        this._element = new google.maps.OverlayView();

        this._element.onAdd = function () {

            this.background = document.createElement('div');
            this.background.style.left = '0px';
            this.background.style.top = '0px';
            this.background.classList.add('underlay');

            const panes = this.getPanes();
            panes.overlayLayer.appendChild(this.background);
        }

        this._element.draw = function () {

            const overlayProjection = this.getProjection();
            const sw = overlayProjection.fromLatLngToDivPixel(
                this.map.getBounds().getSouthWest()
            );

            const ne = overlayProjection.fromLatLngToDivPixel(
                this.map.getBounds().getNorthEast()
            );

            this.background.style.left = sw.x + 'px';
            this.background.style.top = ne.y + 'px';
            this.background.style.width = ne.x - sw.x + 'px';
            this.background.style.height = sw.y - ne.y + 'px';
        }

        this._element.hide = function() {
            this.background.classList.remove('visible')
        }

        this._element.show = function() {
            this.background.classList.add('visible')
        }

        this._element.setMap(this._gMap);

    }

    show() {

        this._element.show();
        this._gMap.setOptions({draggable: false, draggableCursor:'initial'});
    }

    hide() {

        this._element.hide();
        this._gMap.setOptions({draggable: true, draggableCursor:null});
    }
}
