class StoryGrid {

  constructor(el) {
    this._name = 'story-grid';
    this._domElement = el;
    this._content =  el.getElementsByClassName(this._name + '__list')[0];
    this._pagination = el.getElementsByClassName(this._name + '__pagination')[0];
    this._dataContainer = document.createElement('div');
    this._nextBtn = el.getElementsByClassName('button-next')[0];
    this._prevBtn = el.getElementsByClassName('button-prev')[0];

    this._url = window.location.href;

    if (this._pagination) {
      this._paginationItems = Array.from(this._pagination.getElementsByClassName(this._name + '__page-item'));
      this._initialize();
    }
  }

  _initialize () {
    this._addEventListeners();
  }

  _addEventListeners() {
    this._paginationItems.forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        this._ajaxLoadPage(false, item);
      });
    });

    window.addEventListener('popstate', (e) => {
      this._ajaxLoadPage(true);
    });

    this._nextBtn.addEventListener('click', (e) => {
        let _pageId = window.location.href.split('?page=')[1];

        if (!_pageId && this._paginationItems.length > 1) {
            _pageId = 1
        }

        if (_pageId && parseInt(_pageId) !== this._paginationItems.length) {
            this._ajaxLoadPage(false, this._paginationItems[_pageId]);
        }
    })

    this._prevBtn.addEventListener('click', (e) => {
        const _pageId = window.location.href.split('?page=')[1];
        if (_pageId && parseInt(_pageId) > 1) {
            this._ajaxLoadPage(false, this._paginationItems[_pageId - 2]);
        }
    })

  }

  // update url to reflect search query
  _updateHistory(url) {
    history.pushState({}, document.title,  url);
  }

  _updateActiveItem(index) {
      this._paginationItems.forEach((item, _index) => {
          if (index !== _index) {
              item.classList.remove('active')
          } else {
              item.classList.add('active')
          }
      })
  }

  _updatePagination(page) {
    const pageFrom = document.getElementsByClassName('story-grid__pagination-current')[0];
    pageFrom.innerText = page.innerText;
  }

   // on form submit
  _ajaxLoadPage(fromHistory, page) {

    let url;

    if (fromHistory) {
      url = window.location.href;
      const _pageId = window.location.href.split('?page=')[1];
      if (_pageId) {
        page = this._paginationItems[_pageId - 1];
      }

    } else {
      url = this._url.split('?')[0] + '?page=' + page.innerText;
      this._updateHistory(url);
      this._updatePagination(page);
    }

    const _xHttp = new XMLHttpRequest();

    _xHttp.onreadystatechange = (event) => {
      if (_xHttp.readyState === XMLHttpRequest.DONE) {
        document.body.classList.remove('loading')
        if (_xHttp.status === 200) {
          this._parseResults(_xHttp.response);
          this._updateActiveItem(this._paginationItems.indexOf(page))
          window.scrollTo({top: this._content.getBoundingClientRect().top + window.scrollY - 70, behavior: 'smooth' });
        }
      }
    };

    _xHttp.open('GET',  url, true);
    _xHttp.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    document.body.classList.add('loading')
    _xHttp.send();
  }

  _parseResults(results) {

      // update the content
      this._dataContainer.innerHTML = results;
      this._content.innerHTML = this._dataContainer.getElementsByClassName(this._name + '__list')[0].innerHTML;
      this._dataContainer.innerHTML = null;

  }
}

export const initStoryGrid = function () {
    Array.from(document.getElementsByClassName('story-grid')).forEach((container) => {
        new StoryGrid(container);
    });
};
