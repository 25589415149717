import Swiper from "swiper";

export default function productCarousel() {

    const _name = 'product-carousel';

    const _carousels = document.getElementsByClassName(_name);

    Array.from(_carousels).forEach((container) => {
        setupCarousel(container)
    });

    function setupCarousel(carouselEl) {

        const _carousel = carouselEl.getElementsByClassName(_name + '__list ')[0];
        let _carouselDescription = carouselEl.getElementsByClassName(_name + '__description ')[0];
        const _slides = carouselEl.getElementsByClassName(_name + '__slide');

        // init
        let _productCarousel;
        function _initCarousel() {
            _productCarousel = new Swiper(_carousel, {
                direction: 'horizontal',
                centeredSlides: true,
                centerInsufficientSlides: false,
                loop: (_slides.length > 1),
                slidesPerView: 1,
                speed: 600,
                slideToClickedSlide: true,
                watchOverflow: true,
                init: false,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                breakpoints: {
                    513: {
                        slidesPerView: 3,
                        initialSlide: Math.floor((_slides.length - 1) / 2),
                        loop: (_slides.length > 3),
                        speed: 400,
                    },
                    1024: {
                        slidesPerView: 5,
                        initialSlide: Math.floor((_slides.length - 1) / 2),
                        loop: (_slides.length > 5),
                        speed: 400,
                    }
                },
                on: {
                    slideChangeTransitionStart: function() {
                        _changeDisplayedData();
                    },
                    slideChangeTransitionEnd: function() {
                        const _activeSlide = _productCarousel.slides[_productCarousel.activeIndex];
                        _changeDisplayedData(_activeSlide);
                    },
                    init: function() {
                         const _activeSlide = _productCarousel.slides[_productCarousel.activeIndex];
                        _changeDisplayedData(_activeSlide);
                    }
                }
            });
            _productCarousel.init();
        }

        _initCarousel();

        // breakpoints
        let _currentBreakpoint = _getBreakpoint();
        function _getBreakpoint() {

            const _screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

            if (_screenWidth < 512) {
                return 'xs';
            } else if (_screenWidth < 768) {
                return 'sm';
            } else if (_screenWidth < 1024) {
                return 'md';
            } else if (_screenWidth < 1280) {
                return 'lg';
            }
        }

        window.addEventListener('resize', function() {

            const _newBreakpoint = _getBreakpoint();

            if ( _newBreakpoint !== _currentBreakpoint) {

                _currentBreakpoint = _newBreakpoint;

                _productCarousel.destroy(true, true);
                _initCarousel();
            }
        });

        function _changeDisplayedData(activeSlide) {

            if (activeSlide) {

                const _title = activeSlide.dataset.title;
                const _description = activeSlide.dataset.description;

                const _titleField = _carouselDescription.getElementsByClassName('slide__title')[0];
                const _descriptionField = _carouselDescription.getElementsByClassName('slide__description')[0];

                _titleField.innerHTML = _title;
                _descriptionField.innerHTML = _description;

                _carouselDescription.classList.remove('hidden');

            } else {

                _carouselDescription.classList.add('hidden');
            }
        }
     }
}

