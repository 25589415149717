export default function productionProcess() {
    const _name = 'production-process';
    const items = [];
    const cssClassStickyTitleHidden = `${_name}--sticky-title-hidden`;
    const cssClassStickyTitleDark = `${_name}--sticky-title-dark`;
    const cssClassStickyDayLabelContainerHidden = `${_name}--sticky-day-label-container-hidden`;

    let lastTop = 0;

    //dirty old fashioned 'constructor'
    function init() {
        const itemContainers = document.querySelectorAll(`.${_name}__item`);

        itemContainers.forEach((container, i) => {
            items.push({
                'container': container,
                'stickyTitle': container.querySelector(`.${_name}__image-title`),
                'contentTitle': container.querySelector(`.${_name}__content-title`),
                'stickyDayLabelContainer': container.querySelector(`.${_name}__image-day-label-container`),
                'contentDayLabelContainer': container.querySelector(`.${_name}__content-day-label-container`),
                'iconFilled': container.querySelector(`.${_name}__image-icon-container .${_name}__icon--filled`),
                'contentContainer': container.querySelector(`.${_name}__content-container`)
            });
        });
        window.addEventListener('scroll', onScroll);
    }

    function onScroll() {
        const target = window.scrollY || window.pageYOffset;

        for (const item of items) {
            const stickyTitleTop = item.stickyTitle.getBoundingClientRect().top;
            const contentTitleTop = item.contentTitle.getBoundingClientRect().top;
            const stickyDayLabelContainerTop = item.stickyDayLabelContainer.getBoundingClientRect().top;
            const contentDayLabelContainerTop = item.contentDayLabelContainer.getBoundingClientRect().top;
            const containerTop = item.contentContainer.getBoundingClientRect().top - 940;


            const fillDistance = 800;

            if (containerTop <= 0) {
                if (containerTop >= -fillDistance) {
                    const fillPercentage = 100 - (Math.abs(containerTop) / fillDistance * 100);
                    console.log(fillPercentage, containerTop);
                    item.iconFilled.style.clipPath = `inset(${fillPercentage}% 0 0 0)`;
                } else {
                    item.iconFilled.style.clipPath = 'none';
                }
            } else {
                // default clip-path from css file;
            }

            if (stickyTitleTop >= contentTitleTop) {
                item.container.classList.add(cssClassStickyTitleHidden);
            } else {
                item.container.classList.remove(cssClassStickyTitleHidden);
            }

            if (stickyTitleTop >= contentTitleTop - 150) {
                item.container.classList.add(cssClassStickyTitleDark);
            } else {
                item.container.classList.remove(cssClassStickyTitleDark);
            }

            if (stickyDayLabelContainerTop >= contentDayLabelContainerTop) {
                item.container.classList.add(cssClassStickyDayLabelContainerHidden);
            } else {
                item.container.classList.remove(cssClassStickyDayLabelContainerHidden);
            }
        }

        lastTop = target;
    }

    init();
}
