import {Underlay} from "./underlay";
import {addressStrToHTML} from "../utils/address";

export class InfoWindow {

    static get options() {
        return {
            pixelOffset: new google.maps.Size(0, 65),
            maxWidth: 340,
            maxHeight: 340,
            disableAutoPan: true
        }
    }

    constructor(mapPage, labels) {

        this._gMap = mapPage.map;
        this._labels = labels;

        this._infoWindow = null;
        this._underlay = new Underlay(this._gMap);

        this._initialize();
    }

    open(marker) {
        this._underlay.show();
        this._infoWindow.open(this._gMap, marker);
    }

    close() {
        this._infoWindow.close();
        this._underlay.hide();
    }

    toggle(marker, type, location) {

        this._infoWindow.close();
        this._infoWindow.setContent(this._template(type, location));

        if (type === 'farm') {
            this._infoWindow.set("pixelOffset", new google.maps.Size(0, 81));
        } else {
            this._infoWindow.set("pixelOffset", new google.maps.Size(0, 66));
        }

        this.open(marker);
    }

    _initialize() {
        this._infoWindow = new google.maps.InfoWindow();
        this._addEventListeners();
    }

    _addEventListeners() {
        google.maps.event.addListener(this._infoWindow, 'closeclick', () => {
            this._underlay.hide();
        });
    }

    _addLabelImages(labels) {

        let _str = '';

        Array.from(labels).forEach((label) => {
            const _label = this._labels.find((item) => item.id === label);
            if (_label && _label.logo) {
                _str += `<img src="${_label.logo}" />`;
            }
        });

        return _str;
    }

    _template(type, location) {
        const label = this._labels.find(label => label.id === location.labels[0])
        console.log(label)

        return `<div class="map-info-panel hexagon-light">
                    <h4 class="map-info-panel__label">${label.title}</h4>
                    <h3 class="map-info-panel__title">${location.name}</h3>
                    <address class="map-info-panel__address">
                        ${addressStrToHTML(location.address)}
                    </address>
                    <span class="icon icon__${type}"></span>
                </div>`;
    }

    get element() {
        return this._infoWindow;
    }
}
