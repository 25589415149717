import Cookies from 'js-cookie/src/js.cookie';

export default function cookie() {
    const COOKIE_KEY = 'acceptCookie';
    const COOKIE_VAL_ACCEPTED = '1';
    const COOKIE_VAL_DECLINED = '0';

    const CLASS_CONTAINER = '.cookie';
    const CLASS_CONTAINER_HIDDEN = 'cookie--hidden';
    const CLASS_BTN_ACCEPT = '.cookie__button--accept';
    const CLASS_BTN_DECLINE = '.cookie__button--decline';

    document.addEventListener('DOMContentLoaded', () => {
        const hasAcceptedCookies = Cookies.get(COOKIE_KEY);
        const cookieContainer = document.querySelector(CLASS_CONTAINER);

        if (hasAcceptedCookies === undefined) {
            cookieContainer.classList.remove(CLASS_CONTAINER_HIDDEN);

            const cookiesAccept = document.querySelector(CLASS_BTN_ACCEPT);
            cookiesAccept.addEventListener('click', () => {
                Cookies.set(COOKIE_KEY, COOKIE_VAL_ACCEPTED, {expires: 30});
                cookieContainer.classList.add(CLASS_CONTAINER_HIDDEN);
            });

            const cookiesDecline = document.querySelector(CLASS_BTN_DECLINE);
            cookiesDecline.addEventListener('click', () => {
                Cookies.set(COOKIE_KEY, COOKIE_VAL_DECLINED, {expires: 30});
                cookieContainer.classList.add(CLASS_CONTAINER_HIDDEN);
            });
        }
    });
}
